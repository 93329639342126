const FourTexts = () => (
    <div className="container-fluid px-md-5 px-0 pt-5" id="welcome">
        <div className={'row p-5'} style={{ backgroundColor: '#F6F0E4' }}>
            <div className={'row text-center'} style={{ backgroundColor: '#F6F0E4' }}>
                <h1 className="display-4 title-font mb-4">l’Imaginarium</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4 text-font">
                        Ce nom qui interpelle signifie “lieu de l’imaginaire”.
                        Au même titre qu’un cabinet de curiosité, l’Imaginarium cultive un esprit et une cuisine d’imagination et de découvertes.
                        Embarquez, et le temps d’un déjeuner ou d’un dîner, vous serez transportés dans un univers unique, créatif et surprenant.
                    </p>
                </div>
            </div>
            <img src={'/cog.png'} className={'cog'} style={{ display: 'none'}} />
            <img src={'/squid.png'} className={'squid'} style={{ display: 'none'}} />
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 py-5">
                <div className="col d-flex align-items-start">
                    <div>
                        <h3 className="mb-0 fs-1 pb-4 title-font">Le lieu</h3>
                        <p className={'text-font fs-4'}>
                            Au centre du quartier République, L’Imaginarium vous transporte hors du temps dans un décor inspiré des voyages extraordinaires de la fin du XIXeme siècle, entre Jules Verne et Belle Époque.<br /><br />
                            Les saveurs originales réveillent les papilles dans une ambiance chaleureuse et envoûtante.
                            Le voyage continue dans l’assiette avec une carte originale et colorée mettant à l’honneur la gastronomie des quatre coins du monde.
                        </p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div>
                        <h3 className="mb-0 fs-1 pb-4 title-font">La Cuisine</h3>
                        <p className={'text-font fs-4'}>
                            Les Cheffes Carolina et Margot Schioppa Thievet puisent leur inspiration dans leurs origines et leurs voyages.
                            Elles allient les produits de la région à milles épices gourmandes qui transportent vers une aventure des
                            sens.<br /><br />
                            La carte subtile et dépaysante propose également de belles propositions gourmandes végétariennes.
                            L’équipe est également à l’écoute des régimes alimentaires spécifiques annoncés lors des réservations.
                        </p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div>
                        <h3 className="mb-0 fs-1 pb-4 title-font">Nos engagements</h3>
                        <p className={'text-font fs-4'}>
                            La carte hebdomadaire ou mensuelle change au gré des saisons.
                            Les plats sont cuisinés sur place avec passion tout en favorisant une agriculture locale et responsable.<br/><br/>
                            L’Imaginarium s’engage avec l’association les BouillonNantes pour agir vers la transition alimentaire, valoriser l’humain et le savoir faire et promouvoir les nouvelles démarches d’écorésponsabilité, et de partage pour une restauration ayant autant de goût que de sens.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default FourTexts;
