import React from "react";
import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";

export default function SimpleMap(){
    const defaultProps = {
        center: {
            lat: 47.20621671687638,
            lng: -1.5549355
        },
        zoom: 15
    };

    return (
        <div
            className={'container-fluid text-center px-md-5 px-0'}
        >
            <div className={"row"} style={{backgroundColor: 'rgb(48, 48, 48)'}}>
                <div style={{ height: '50vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyB00Kfhd5N3jv4iS9CrMbpNNgtCkKzGB0I" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                    >
                        <Marker lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
                    </GoogleMapReact>
                </div>
            </div>

        </div>
    );
}
