const Footer = () => (
    <div className="container-fluid footer">
        <footer className="container py-5">
            <div className={'row'}>
                <div className={'col-12 col-md-3 text-center'}>
                    <img style={{ width: '80%', top: '30%', position: 'relative', transform: 'translateY(-50%)' }} src={'/logo.png'}/>
                </div>
                <div className={'col-12 col-md-3'}>
                    <div className={'title-font fs-3'}>
                        Horaires
                    </div>
                    <table className={'text-font'}>
                        <tr>
                            <td>Lun:</td>
                            <td>fermé</td>
                        </tr>
                        <tr>
                            <td>Mar:</td>
                            <td>12h00 - 14h30</td>
                        </tr>
                        <tr>
                            <td>Mer:</td>
                            <td>12h00 - 14h30</td>
                        </tr>
                        <tr>
                            <td>Jeu:</td>
                            <td>12h00 - 14h30 / 19h30 - 22h00</td>
                        </tr>
                        <tr>
                            <td>Ven:</td>
                            <td>12h00 - 14h30 / 19h30 - 22h00</td>
                        </tr>
                        <tr>
                            <td>Sam:</td>
                            <td>19h30 - 22h00</td>
                        </tr>
                        <tr>
                            <td>Dim:</td>
                            <td>fermé</td>
                        </tr>
                    </table>
                </div>
                <div className={'col-12 col-md-3'}>
                    <div className={'title-font fs-3'}>
                        Imaginarium
                    </div>
                    <ul className={'text-font'}>
                        <li>Restaurant île de Nantes</li>
                        <li>Restaurant fait-maison Nantes</li>
                        <li>Restaurant produits locaux</li>
                        <li>Nantes Restaurant Végétarien</li>
                        <li>Nantes Restaurant romantique</li>
                        <li>Restaurant décor original Nantes</li>
                        <li>Restaurant bistronomique Nantes</li>
                    </ul>
                </div>
                <div className={'col-12 col-md-3'}>
                    <div className={'title-font fs-3'}>
                        Adresse et Réseaux
                    </div>
                    <div>
                        <div className={'text-font'}>
                            28 Rue Louis Blanc, 44200 Nantes
                        </div>
                        <div>
                            <a className={'px-1'} href={'https://twitter.com/imaginarium_nao'} target={'_blank'}><img style={{ width: '20px' }} src={'/twitter.png'} alt={'twitter link'} /></a>
                            <a className={'px-1'} href={'https://www.instagram.com/imaginarium_nantes'} target={'_blank'}><img style={{ width: '20px' }} src={'/Instagram_icon.png'} alt={'instagram link'} /></a>
                            <a className={'px-1'} href={'https://www.facebook.com/imaginariumnantes'} target={'_blank'}><img style={{ width: '20px' }} src={'/facebook.png'} alt={'facebook link'} /></a>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-center"  style={{ color: '#F6F0E4'}}>© {new Date().getFullYear()} Imaginarium</p>
        </footer>
    </div>
);

export default Footer;
