import React, {useState} from "react";
import Header from "./Header";
import TextAndOneImage from "./TextAndOneImage";
import ThreeImages from "./ThreeImages";
import FourTexts from "./FourTexts";
import BookBlock from "./BookBlock";
import BookWidget from "./BookWidget";
import Footer from "./Footer";
import BookBlock2 from "./BookBlock2";
import SimpleMap from "./Map";

const Homepage = () => {
    const [key, setKey] = useState('');

    return (
        <div>
            {
                true ? (
                    <>
                        <Header />
                        <FourTexts />
                        <ThreeImages />
                        <TextAndOneImage />
                        <BookBlock2 />
                        <SimpleMap />
                        <Footer />
                        <BookWidget />
                    </>
                ) : (
                    <div
                        className={'container-fluid'}
                        style={{
                            backgroundColor: '#F6F0E4',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                        }}
                        >
                        <div className={'align-middle'} style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <div className={'row text-center align-middle'}>
                                <span className='text-font' style={{ color: '#998b72' }}>Bienvenue à</span><br/>
                                <span className='title-font fs-1' style={{ color: '#2F6163' }}>l'Imaginarium</span>
                            </div>
                            <div className='text-center text-font'>
                                <div>Du mardi au vendredi: 12h00 - 14h30</div>
                                <div>Du jeudi au samedi: 19h30 - 22h00</div>
                                <div>28 rue Louis Blanc, 44200, Nantes</div>

                                <div style={{ color: '#998b72', marginTop: '10%' }}>
                                    <span className='text-font' >Site en maintenance, veuillez entrer le mot de passe pour continuer</span><br/><br/>
                                    <input
                                        style={{
                                            width: 'fit-content',
                                            color: '#998b72',
                                            borderRadius: '25px',
                                            fontSize: '25px',
                                            textAlign: 'center',
                                            backgroundColor: '#F6F0E4'
                                        }}
                                        className='title-font'
                                        value={key} onChange={e => setKey(e.target.value)} type='text' />
                                </div >
                                <div style={{ marginTop: '10%'}}>
                                    <div className={'fs-1'} style={{ color: '#998b72' }}>Réservations :</div>
                                    Tel: <a
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black'
                                    }}
                                    href="tel:09 70 66 97 01" className="title-font fs-1">09 70 66 97 01</a>
                                </div>
                                <div>
                                    Sms: <a
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black'
                                    }}
                                    href="tel:07 49 04 98 81" className="title-font fs-1">07 49 04 98 81</a>
                                </div>

                            </div>
                        </div>

                    </div>
                )
            }
        </div>
    );
};

export default Homepage;
