const ThreeImages = () => (
    <div className="container-fluid px-md-5 px-0" id="custom-cards">
        <div className={'row px-5'} style={{ backgroundColor: '#F6F0E4' }}>
            <div className="row row-cols-1 row-cols-lg-4 align-items-stretch g-4 title-font">
                <div className="col" style={{ height: '300px'}}>
                    <div className="card h-100 overflow-hidden shadow-lg"
                         style={{
                             backgroundImage: `url("/rec1.jpeg")`,
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat',
                             backgroundPosition: 'center'
                         }}
                    />
                </div>
                <div className="col" style={{ height: '300px'}}>
                    <div className="card h-100 overflow-hidden shadow-lg"
                         style={{
                             backgroundImage: `url("/rec2.jpeg")`,
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat',
                             backgroundPosition: 'center'
                         }}
                    />
                </div>
                <div className="col" style={{ height: '300px'}}>
                    <div className="card h-100 overflow-hidden shadow-lg"
                         style={{
                             backgroundImage: `url("/rec3.jpeg")`,
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat',
                             backgroundPosition: 'center'
                         }}
                    />
                </div>
                <div className="col" style={{ height: '300px'}}>
                    <div className="card h-100 overflow-hidden shadow-lg"
                         style={{
                             backgroundImage: `url("/rec4.jpeg")`,
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat',
                             backgroundPosition: 'center'
                         }}
                    />
                </div>
            </div>
        </div>

    </div>
);

export default ThreeImages;
