import './App.css';
import Homepage from "./components/homepage/Homepage";

function App() {
  return (
      <div id={'container'}>
        <Homepage />
      </div>
  );
}

export default App;
