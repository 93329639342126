const BookWidget = () => (
    <a
        href="#reserver"
        className={'book-widget text-font fs-5'}
    >
        Réserver
    </a>
);

export default BookWidget;
