const Header = () => (
    <>
        <div
            className={'container-fluid min-vh-100 d-flex flex-column'}
            style={{
                backgroundImage: `url("/background.jpg")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        />
        <div
            className={'container-fluid min-vh-100 d-flex flex-column d-flex w-100 h-100 p-3 mx-auto flex-column'}
            style={{
                position: 'absolute',
                top: 0,
                backgroundColor: 'rgba(0, 0, 0, .5)',
                color: 'white'
            }}
        >
            <div className="d-flex w-100 h-100 p-3 mx-auto flex-column">
                <nav className="nav nav-masthead ">
                </nav>

                <div className="d-flex m-auto">
                    <img style={{ height: '130%', maxWidth: '100%' }} src={'/logo.png'}/>
                </div>

                <div className="mt-auto text-center">
                    <a href={'#welcome'}
                       className="title-font fs-3"
                       style={{
                            textDecoration: 'none',
                            color: 'white'
                        }}
                    >
                        Bienvenue
                    </a>
                    <p className="lead">▼</p>
                </div>
            </div>
        </div>
</>
);

export default Header;
