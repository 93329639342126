const TextAndOneImage = () => (
    <div className="container-fluid px-md-5 px-0 text-center">
        <div className={'row pt-5'} style={{ backgroundColor: '#F6F0E4' }}>
            <hr />
            <h1 className="display-4 title-font mb-4">À la carte</h1>
            <div className="row row-cols-1 row-cols-lg-2 align-items-stretch g-4 title-font px-5">
                <div className="col">
                    <img style={{ width: '100%'}} src={'/menu/menu1.jpg'} />
                </div>
                <div className="col">
                    <img style={{ width: '100%'}} src={'/menu/menu2.jpg'} />
                </div>
            </div>
        </div>
    </div>
);

export default TextAndOneImage;
