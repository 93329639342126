const BookBlock = () => (
    <div
        className={'container-fluid text-center px-md-5 px-0'}
        id={'reserver'}
    >
        <div className={'row'}>
            <div style={{ backgroundColor: '#303030', color: '#F6F0E4'}}>
                <div className="container py-5 align-items-md-center g-5">
                    <div className="col d-flex flex-column gap-2">
                        <p className="text-font fs-4 mt-5">Pour toute réservation, vous pouvez nous appeler du mardi au samedi de 9h à 19h au <a
                            style={{
                                textDecoration: 'none',
                                color: 'rgb(246, 240, 228)'
                            }}
                            href="tel:0970669701" className="title-font fs-1">09 70 66 97 01</a>
                        </p>
                        <p className="text-font fs-4 mt-5">Réservation par sms <a
                            style={{
                                textDecoration: 'none',
                                color: 'rgb(246, 240, 228)'
                            }}
                            href="tel:0749049881" className="title-font fs-1">07 49 04 98 81</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default BookBlock;
